import React, { useEffect } from 'react'

import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import ArticleProject from 'mx/blog/article-project'
import Layout from 'mx/layout'
import { sanitizeUrl } from 'views/utils'

const BlogProjectArticle = (props: any) => {
  const {
    actionText,
    actionUrl,
    affiliateCode,
    author,
    category,
    content,
    credits,
    date,
    includeIntroduction,
    introduction,
    image,
    spaceBetweenBlocks,
    title,
  } = props.pageContext

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: title,
    })
  }, [])

  return (
    <Layout>
      <ArticleProject
        hero={{
          actionText,
          actionUrl,
          affiliateCode,
          author,
          category,
          date,
          introduction,
          image,
          title,
          includeIntroduction,
        }}
        content={content}
        credits={credits}
        spaceBetweenBlocks={spaceBetweenBlocks}
      />
    </Layout>
  )
}

export default BlogProjectArticle

export const Head = (props: any) => {
  const { introduction, image, title, publishingUrl } = props.pageContext
  const path = `/blog/article/${publishingUrl || sanitizeUrl(title)}`

  return (
    <SEO
      image={
        image.localFile.childImageSharp.gatsbyImageData.images.fallback.src
      }
      title={title}
      description={introduction}
      path={path}
    />
  )
}
