import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import moment from 'moment'
import Slider from 'react-slick'
import tw from 'twin.macro'

import 'twin.macro'

import { settings as defaultSettings } from 'mx/blog/slider'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { SubmitMailing } from 'mx/locations/submit-mailing'
import { FormContactSource } from 'types/crm'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: -75 },
}

const Hero = ({
  actionText,
  actionUrl,
  author,
  category,
  date,
  image,
  introduction,
  includeIntroduction,
  title,
}: {
  actionText?: string
  actionUrl?: string
  author: {
    firstName?: string
    lastName?: string
  }
  category: string
  date: string
  image: {
    title: string
    description: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
  includeIntroduction: boolean
  introduction?: string
  title: string
}) => {
  return (
    <section tw="flex flex-col h-full overflow-hidden md:(grid grid-cols-2 items-stretch mb-24)">
      <div tw="order-2 h-full flex flex-col justify-between mb-12 md:(pr-5 order-1 w-full mb-0 items-stretch)">
        <div tw="mt-6 pl-4 md:(pl-0 mt-0)">
          {category && (
            <>
              <p tw="text-2xl font-thin italic text-gray-600 lg:(text-3xl)">
                {category}
              </p>
              <div tw="w-10 h-1 border-b border-gray-800 mb-6" />
            </>
          )}
          <H1>{title}</H1>
          {includeIntroduction ? (
            <p
              tw="font-light text-gray-800 leading-relaxed max-w-xl mt-4 mb-6"
              dangerouslySetInnerHTML={{
                __html: introduction ?? '',
              }}
            />
          ) : null}
          {actionUrl && actionText ? (
            <Button
              tw="my-8 md:my-4"
              color="transparentBlack"
              rounded
              onClick={() => {
                navigate(actionUrl)
              }}
            >
              {actionText}
            </Button>
          ) : null}
        </div>
        <div tw="mx-4 mt-10 border-t border-gray-800 flex justify-between pt-3 md:(mx-0 mt-0)">
          <p tw="capitalize text-gray-500 text-base">
            {author?.firstName} {author?.lastName}
          </p>
          <p tw="capitalize text-gray-500 text-base oldstyle-nums">
            {moment(date).format('MMMM D, YYYY')}
          </p>
        </div>
      </div>
      <div tw="order-1 md:order-2 w-full h-auto md:(m-auto)">
        {image.localFile?.childImageSharp?.gatsbyImageData ? (
          <GatsbyImage
            tw="h-full"
            title={image.title ?? ''}
            alt={image.description ?? ''}
            image={image.localFile.childImageSharp.gatsbyImageData}
          />
        ) : null}
      </div>
    </section>
  )
}

const P = (props: any) => (
  <p tw="font-light text-gray-900 leading-relaxed max-w-md mb-6" {...props} />
)

const Q = (props: any) => (
  <span
    tw="text-2xl font-thin text-gray-900 max-w-md py-4 md:(text-3xl)"
    {...props}
  />
)

const H1 = (props: any) => (
  <h1
    tw="text-4xl leading-tight font-thin text-gray-900 max-w-md md:(pr-2 leading-tight max-w-lg)"
    {...props}
  />
)
const H2 = (props: any) => (
  <h2
    tw="text-3xl text-left ml-0 font-thin leading-tight capitalize text-gray-900 max-w-xl pb-4 md:(text-4xl)"
    {...props}
  />
)
const H3 = (props: any) => (
  <h3
    tw="text-2xl ml-0 font-thin leading-tight text-gray-900 max-w-lg pb-4 md:(text-3xl)"
    {...props}
  />
)
const H4 = (props: any) => (
  <h4
    tw="text-xl font-thin text-gray-900 max-w-lg pb-4 md:(text-2xl)"
    {...props}
  />
)
const H5 = (props: any) => (
  <h5
    tw="text-xl font-thin text-gray-900 max-w-lg pb-4 capitalize md:(text-xl)"
    {...props}
  />
)
const H6 = (props: any) => (
  <h6
    tw="text-lg font-thin text-gray-900 max-w-lg pb-4 md:(text-xl)"
    {...props}
  />
)

const SectionTwoStacked = ({ section }: { section: any }) => {
  return (
    <div tw="px-4 md:px-0">
      {renderRichText(section.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.nodeType === 'paragraph') {
              return <P>{children}</P>
            }
          },
          [BLOCKS.QUOTE]: (_, children) => {
            return <Q>{children}</Q>
          },
        },
        renderText: (text) =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      })}

      <div tw="mt-8 space-y-6 w-full flex flex-col md:(items-end mt-16 space-y-20 space-y-10)">
        <div tw="md:w-11/12 lg:w-10/12 bg-gray-100">
          <GatsbyImage
            tw="object-contain"
            alt={section?.content?.references?.[0]?.localFile.name}
            image={section?.content?.references?.[0]?.gatsbyImageData}
          />
        </div>
        <div tw="bg-gray-100 md:(w-11/12) lg:(w-10/12)">
          <GatsbyImage
            tw="object-contain"
            alt={section?.content?.references?.[1]?.localFile.name}
            image={section?.content?.references?.[1]?.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  )
}
const SectionSingleStacked = ({
  section,
  interview = false,
}: {
  section: any
  interview?: boolean
}) => {
  return (
    <div tw="px-4 md:(px-0)">
      {renderRichText(section.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            const renderVideo = section?.content?.references?.[0]?.embedUrl
            if (node.nodeType === 'paragraph') {
              return !renderVideo ? (
                <P tw="md:(max-w-xl pr-4)">{children}</P>
              ) : (
                children
              )
              return <P tw="md:(max-w-xl pr-4)">{children}</P>
            }
          },
          [BLOCKS.QUOTE]: (_, children) => {
            return interview ? (
              <Q tw="text-4xl leading-tighter md:(max-w-5xl ml-0 py-0 !my-24)">
                {children}
              </Q>
            ) : (
              <Q>{children}</Q>
            )
          },
          [BLOCKS.HEADING_1]: (_, children) => {
            return <H1>{children}</H1>
          },
          [BLOCKS.HEADING_2]: (_, children) => {
            return <H2>{children}</H2>
          },
          [BLOCKS.HEADING_3]: (_, children) => {
            return <H3>{children}</H3>
          },
          [BLOCKS.HEADING_4]: (_, children) => {
            return <H4>{children}</H4>
          },
          [BLOCKS.HEADING_5]: (_, children) => {
            return <H5>{children}</H5>
          },
          [BLOCKS.HEADING_6]: (_, children) => {
            return <H6>{children}</H6>
          },
        },
        renderText: (text) =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      })}
      {section?.content?.references?.[0]?.embedUrl && (
        <div tw="my-12 flex flex-col aspect-w-16 aspect-h-9 mx-auto md:(max-w-2xl pb-[23rem]) lg:(max-w-3xl pb-[27rem]) xl:(max-w-5xl pb-[36rem])">
          <iframe
            title={section?.content?.references?.[0]?.title}
            src={section?.content?.references?.[0]?.embedUrl}
            allowFullScreen
          ></iframe>
        </div>
      )}
      {!section.isTitle &&
        section?.content?.references?.[0]?.gatsbyImageData && (
          <div
            tw="mt-8 space-y-6 w-full flex flex-col md:(items-end mt-16 space-y-10)"
            css={section.isLeft && tw`md:!items-start`}
          >
            <div tw="md:(w-11/12) lg:(w-10/12)">
              <GatsbyImage
                tw="object-contain"
                title={section?.content?.references?.[0]?.title ?? ''}
                alt={section?.content?.references?.[0]?.description ?? ''}
                image={section?.content?.references?.[0]?.gatsbyImageData}
              />
            </div>
          </div>
        )}
    </div>
  )
}

const SectionThreeGrid = ({ section }: { section: any }) => {
  return (
    <div tw="px-4 md:px-0">
      {renderRichText(section.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.nodeType === 'paragraph') {
              return <P>{children}</P>
            }
          },
          [BLOCKS.QUOTE]: (_, children) => {
            return <Q>{children}</Q>
          },
          [BLOCKS.HEADING_1]: (_, children) => {
            return <H1>{children}</H1>
          },
          [BLOCKS.HEADING_2]: (_, children) => {
            return <H2>{children}</H2>
          },
          [BLOCKS.HEADING_3]: (_, children) => {
            return <H3>{children}</H3>
          },
          [BLOCKS.HEADING_4]: (_, children) => {
            return <H4>{children}</H4>
          },
          [BLOCKS.HEADING_5]: (_, children) => {
            return <H5>{children}</H5>
          },
          [BLOCKS.HEADING_6]: (_, children) => {
            return <H6>{children}</H6>
          },
        },
        renderText: (text) =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      })}
      <div tw="mt-8 w-full flex flex-col md:(items-end mt-16 space-y-20)">
        <div tw="grid gap-x-4 gap-y-4 grid-cols-2 md:(w-11/12 gap-x-10 gap-y-10) lg:(w-10/12)">
          <div tw="bg-gray-100">
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[0]?.localFile.name}
              image={section?.content?.references?.[0]?.gatsbyImageData}
            />
          </div>
          <div tw="bg-gray-100">
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[1]?.localFile.name}
              image={section?.content?.references?.[1]?.gatsbyImageData}
            />
          </div>
          <div tw="bg-gray-100 col-span-2">
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[2]?.localFile.name}
              image={section?.content?.references?.[2]?.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionThreeRow = ({ section }: { section: any }) => {
  return (
    <div tw="px-4 md:px-0">
      {renderRichText(section.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.nodeType === 'paragraph') {
              return <P tw="md:(max-w-xl pr-4)">{children}</P>
            }
          },
          [BLOCKS.QUOTE]: (_, children) => {
            return <Q>{children}</Q>
          },
          [BLOCKS.HEADING_1]: (_, children) => {
            return <H1>{children}</H1>
          },
          [BLOCKS.HEADING_2]: (_, children) => {
            return <H2>{children}</H2>
          },
          [BLOCKS.HEADING_3]: (_, children) => {
            return <H3>{children}</H3>
          },
          [BLOCKS.HEADING_4]: (_, children) => {
            return <H4>{children}</H4>
          },
          [BLOCKS.HEADING_5]: (_, children) => {
            return <H5>{children}</H5>
          },
          [BLOCKS.HEADING_6]: (_, children) => {
            return <H6>{children}</H6>
          },
        },
        renderText: (text) =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      })}
      <div tw="mt-10 w-full flex flex-col md:(items-end mt-16)">
        <div
          tw="flex space-x-4 md:(space-x-0 w-11/12 grid grid-cols-3 gap-x-4) lg:(gap-x-10 w-10/12)"
          className="scroll-snap-x"
        >
          <div
            className="scroll-snap-member"
            tw="shrink-0 w-10/12 bg-gray-100 md:(w-auto)"
          >
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[0]?.localFile.name}
              image={section?.content?.references?.[0]?.gatsbyImageData}
            />
          </div>
          <div
            className="scroll-snap-member"
            tw="shrink-0 w-10/12 bg-gray-100 md:(w-auto)"
          >
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[1]?.localFile.name}
              image={section?.content?.references?.[1]?.gatsbyImageData}
            />
          </div>
          <div
            className="scroll-snap-member"
            tw="shrink-0 w-10/12 bg-gray-100 md:(w-auto)"
          >
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[2]?.localFile.name}
              image={section?.content?.references?.[2]?.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionTwoRow = ({ section }: { section: any }) => {
  return (
    <div tw="px-4 md:px-0">
      {renderRichText(section.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            if (node.nodeType === 'paragraph') {
              return <P tw="md:(max-w-xl pr-4)">{children}</P>
            }
          },
          [BLOCKS.QUOTE]: (_, children) => {
            return <Q>{children}</Q>
          },
          [BLOCKS.HEADING_1]: (_, children) => {
            return <H1>{children}</H1>
          },
          [BLOCKS.HEADING_2]: (_, children) => {
            return <H2>{children}</H2>
          },
          [BLOCKS.HEADING_3]: (_, children) => {
            return <H3>{children}</H3>
          },
          [BLOCKS.HEADING_4]: (_, children) => {
            return <H4>{children}</H4>
          },
          [BLOCKS.HEADING_5]: (_, children) => {
            return <H5>{children}</H5>
          },
          [BLOCKS.HEADING_6]: (_, children) => {
            return <H6>{children}</H6>
          },
        },
        renderText: (text) =>
          text
            .split('\n')
            .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      })}
      <div tw="mt-10 w-full flex flex-col md:(items-end mt-16)">
        <div
          tw="flex space-x-4 md:(space-x-0 w-11/12 grid grid-cols-2 gap-x-4) lg:(gap-x-10 w-10/12)"
          className="scroll-snap-x"
        >
          <div
            className="scroll-snap-member"
            tw="shrink-0 w-10/12 bg-gray-100 md:(w-auto) "
          >
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[0]?.localFile.name}
              image={section?.content?.references?.[0]?.gatsbyImageData}
            />
          </div>
          <div
            className="scroll-snap-member"
            tw="shrink-0 w-10/12 bg-gray-100 md:(w-auto)"
          >
            <GatsbyImage
              tw="object-contain"
              alt={section?.content?.references?.[1]?.localFile.name}
              image={section?.content?.references?.[1]?.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionSingleSide = ({
  section,
  interview = false,
  gallery,
  galleryImages,
}: {
  section?: any
  interview?: boolean
  gallery?: boolean
  galleryImages?: SliderSingleSideProps[]
}) => {
  return (
    <div
      tw="flex-col space-y-8 px-4 flex md:(px-0 flex-row space-y-0)"
      css={section.isLeft && tw`md:!flex-row-reverse`}
    >
      <div tw="md:(w-6/12 relative)" css={section.isLeft && tw`md:!pl-8`}>
        {renderRichText(section.content, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
              if (node.nodeType === 'paragraph') {
                return <P tw="md:(max-w-xl pr-4)">{children}</P>
              }
            },
            [BLOCKS.QUOTE]: (_, children) => {
              return <Q>{children}</Q>
            },
            [BLOCKS.HEADING_1]: (_, children) => {
              return <H1>{children}</H1>
            },
            [BLOCKS.HEADING_2]: (_, children) => {
              return <H2>{children}</H2>
            },
            [BLOCKS.HEADING_3]: (_, children) => {
              return <H3>{children}</H3>
            },
            [BLOCKS.HEADING_4]: (_, children) => {
              return <H4>{children}</H4>
            },
            [BLOCKS.HEADING_5]: (_, children) => {
              return <H5>{children}</H5>
            },
            [BLOCKS.HEADING_6]: (_, children) => {
              return <H6>{children}</H6>
            },
          },
          renderText: (text) =>
            text
              .split('\n')
              .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
        })}
        {section.actionText && section.actionUrl && (
          <div tw="space-y-8 mt-4 md:mt-8 xl:space-x-4">
            <Button
              color="transparentBlack"
              rounded
              onClick={() => {
                navigate(section.actionUrl)
              }}
            >
              {section.actionText}
            </Button>
            {section.secondaryActionText && (
              <Button
                color="transparentBlack"
                rounded
                onClick={() => {
                  navigate(section.secondaryActionUrl)
                }}
              >
                {section.secondaryActionText}
              </Button>
            )}
          </div>
        )}
        {section.actionDescription?.references?.[0]?.gatsbyImageData && (
          <div tw="mt-12 md:mt-16">
            <div tw="md:(w-7/12)">
              <GatsbyImage
                tw="object-contain"
                alt={
                  section?.actionDescription?.references?.[0]?.description ?? ''
                }
                title={section?.actionDescription?.references?.[0]?.title ?? ''}
                image={
                  section?.actionDescription?.references?.[0]?.gatsbyImageData
                }
              />
            </div>
          </div>
        )}
        {section.actionDescription && !section.enableSignUp && (
          <div tw="mt-4 md:mt-8">
            {renderRichText(section.actionDescription, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  if (node.nodeType === 'paragraph') {
                    return <P tw="md:(max-w-xl pr-4)">{children}</P>
                  }
                },
                [BLOCKS.QUOTE]: (_, children) => {
                  return <Q>{children}</Q>
                },
                [BLOCKS.HEADING_1]: (_, children) => {
                  return <H1>{children}</H1>
                },
                [BLOCKS.HEADING_2]: (_, children) => {
                  return <H2>{children}</H2>
                },
                [BLOCKS.HEADING_3]: (_, children) => {
                  return <H3>{children}</H3>
                },
                [BLOCKS.HEADING_4]: (_, children) => {
                  return <H4>{children}</H4>
                },
                [BLOCKS.HEADING_5]: (_, children) => {
                  return <H5>{children}</H5>
                },
                [BLOCKS.HEADING_6]: (_, children) => {
                  return <H6>{children}</H6>
                },
              },
              renderText: (text) =>
                text
                  .split('\n')
                  .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
            })}
          </div>
        )}
        {section.enableSignUp && (
          <div tw="md:w-9/12">
            {section.actionDescription && (
              <>
                {renderRichText(section.actionDescription, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => {
                      if (node.nodeType === 'paragraph') {
                        return (
                          <p tw="text-base max-w-xs font-light text-gray-900 leading-normal mb-3 md:(max-w-md)">
                            {children}
                          </p>
                        )
                      }
                    },
                  },
                  renderText: (text) =>
                    text
                      .split('\n')
                      .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
                })}
              </>
            )}
            <SubmitMailing
              contactSource={FormContactSource.SIGNUP_BLOG_CAESARSTONE}
              buttonText={section.actionText}
              placeholder={'you@example.com'}
            />
          </div>
        )}
      </div>
      <div tw="md:(w-6/12)">
        {interview ? (
          <>
            <div tw="md:h-full">
              <GatsbyImage
                tw="object-contain md:(h-5/6 object-cover)"
                alt={section?.content?.references?.[0]?.description ?? ''}
                title={section?.content?.references?.[0]?.title ?? ''}
                image={section?.content?.references?.[0]?.gatsbyImageData}
              />
              <div tw="my-6 font-thin leading-tight text-3xl max-w-xs w-5/6 pb-6 md:(font-light max-w-lg text-4xl w-auto leading-tighter)">
                Book a free 1:1 consultation
                <div>
                  <Button
                    tw="my-4 md:mb-0"
                    color="transparentBlack"
                    rounded
                    onClick={() => {
                      navigate('/welcome/new/project-info/get-started/')
                    }}
                  >
                    Book
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : gallery ? (
          <SliderSingleSide options={galleryImages ?? []} />
        ) : section?.content?.references?.[0]?.embedUrl ? (
          <div tw="aspect-w-9 aspect-h-16 max-w-sm mx-auto py-80 md:(mt-8)">
            <iframe
              title={section?.content?.references?.[0]?.title}
              src={section?.content?.references?.[0]?.embedUrl}
              allowFullScreen
            ></iframe>
          </div>
        ) : section?.content?.references?.[0] ? (
          <GatsbyImage
            tw="object-contain"
            alt={section?.content?.references?.[0]?.description ?? ''}
            title={section?.content?.references?.[0]?.title ?? ''}
            image={section?.content?.references?.[0]?.gatsbyImageData}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

const Section = ({ section }: { section: any }) => {
  switch (section.type) {
    case '2-stacked':
      return <SectionTwoStacked section={section} />
    case '3-grid':
      return <SectionThreeGrid section={section} />
    case '3-row':
      return <SectionThreeRow section={section} />
    case '2-row':
      return <SectionTwoRow section={section} />
    case '1-side':
      return (
        <SectionSingleSide
          section={section}
          interview={section.isInterview}
          gallery={section.isGallery}
          galleryImages={section?.content?.references}
        />
      )
    case '1-full':
      return (
        <SectionSingleStacked
          section={section}
          interview={section.isInterview}
        />
      )
    default:
      return null
  }
}
const spaceSize = {
  small: tw`space-y-8 md:(space-y-0)`,
  normal: tw`space-y-10 md:(space-y-12)`,
  big: tw`space-y-16 md:(space-y-20)`,
}

const ArticleProject = ({
  hero,
  content,
  credits,
  spaceBetweenBlocks,
}: {
  hero: {
    actionText?: string
    actionUrl?: string
    affiliateCode?: string
    author: {
      firstName?: string
      lastName?: string
    }
    category: string
    date: any
    introduction: string
    image: {
      title: string
      description: string
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }
    title: string
    includeIntroduction: boolean
  }
  content: any
  credits?: string[]
  spaceBetweenBlocks?: keyof typeof spaceSize
}) => {
  return (
    <article tw="max-w-screen-xl pb-12 md:(mx-4 py-24) lg:(pb-32 pt-24 mx-8) xl:(mx-auto)">
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <Hero {...hero} />
        <div
          css={[
            spaceBetweenBlocks
              ? spaceSize[spaceBetweenBlocks]
              : spaceSize['normal'],
          ]}
        >
          {content?.references?.map((reference: any[], idx: number) => (
            <Section section={reference} key={idx} />
          ))}
        </div>
        {credits ? (
          <div tw="mt-6 pb-6 ml-4 lg:(ml-0)">
            <p tw="capitalize text-gray-800 text-base">{credits}</p>
          </div>
        ) : null}
      </motion.div>
    </article>
  )
}

export type SliderSingleSideProps = {
  title: string
  description: string
  gatsbyImageData: IGatsbyImageData
}

export class SliderSingleSide extends React.Component<{
  options: SliderSingleSideProps[]
}> {
  render = () => {
    const settings = {
      ...defaultSettings,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight: false,
      variableWidth: false,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            adaptiveHeight: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            adaptiveHeight: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            swipe: true,
            swipeToSlide: true,
          },
        },
        {
          breakpoint: 480,
          adaptiveHeight: false,
          variableWidth: false,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            swipeToSlide: true,
          },
        },
      ],
    }

    return (
      <div tw="mx-auto mb-10 md:(w-full mb-16)">
        <Slider {...settings}>
          {this.props.options.map((option, index) => {
            return (
              <div tw="relative p-2" key={index}>
                <div tw="relative space-y-3">
                  <div tw="overflow-hidden relative">
                    <div tw="absolute top-0 left-0 right-0 bottom-0 w-full h-full z-10" />
                    <GatsbyImage
                      tw="object-contain"
                      alt={option?.description ?? ''}
                      title={option?.title ?? ''}
                      image={option?.gatsbyImageData}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}

export default ArticleProject
