import React from 'react'

import 'twin.macro'
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'

function NextArrow(props: any) {
  const { onClick } = props
  return (
    <div
      tw="absolute top-1/2 cursor-pointer right-2 w-8 h-8 bg-white/70 flex items-center justify-center rounded-full text-gray-900 z-10 transform -translate-y-1/2 md:(left-15 -bottom-16 top-auto border border-gray-200)"
      onClick={onClick}
    >
      <ArrowRightIcon color="currentColor" />
    </div>
  )
}

function PrevArrow(props: any) {
  const { onClick } = props
  return (
    <div
      tw="absolute top-1/2 cursor-pointer left-2 z-10 w-8 h-8 bg-white/70 flex items-center justify-center rounded-full text-gray-900 z-10 transform -translate-y-1/2 md:(left-2 -bottom-16 top-auto border border-gray-200)"
      onClick={onClick}
    >
      <ArrowLeftIcon color="currentColor" />
    </div>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  appendDots: (dots: any) => <ul tw="!bottom-4">{dots}</ul>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export { settings }
